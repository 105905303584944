import shouldHide from './hiddenItems';
import removeInvalidLocations from './common/removeInvalidLocations';
import { getAssoicatedCountries } from './blockCountryPurchase';
import { getLoctionsForCustomer } from './customerTagRules';

export function groupByLocations(variantLocations, settings) {
  return new Promise(function(resolve) {
    if (shouldHide(settings)) {
      resolve(null);
    }
    const grouped = variantLocations.reduce(function(
      accumulator,
      variantLocation
    ) {
      const validLocations = removeInvalidLocations(
        variantLocation.inventoryLocations,
        settings
      );

      validLocations.forEach(function(inventoryLocation) {
        if (!accumulator[inventoryLocation.location.id]) {
          accumulator[inventoryLocation.location.id] = {
            location: inventoryLocation.location,
            variants: []
          };
        }
        const variant = getVariant(
          variantLocation,
          inventoryLocation,
          settings
        );
        if (variant) {
          accumulator[inventoryLocation.location.id].variants.push(variant);
        }
      });
      return accumulator;
    },
    {});

    getAssoicatedCountries(settings).then(function(associatedLocationIds) {
      if (associatedLocationIds) {
        const groupedForCountry = filterGroupedBy(
          associatedLocationIds,
          grouped
        );
        if (groupedForCountry) {
          resolve(groupedForCountry);
          return;
        }
      } else {
        //filter by customer tags
        const assoicatedLocations = getLoctionsForCustomer(settings);
        const associatedGroup = filterGroupedBy(assoicatedLocations, grouped);
        if (associatedGroup) {
          resolve(associatedGroup);
          return;
        }
      }
      resolve(grouped);
    });
  });
}

function filterGroupedBy(associatedLocationIds, grouped) {
  if (associatedLocationIds) {
    const groupedLocations = {};
    associatedLocationIds.forEach(function(locationId) {
      if (grouped[locationId]) {
        groupedLocations[locationId] = grouped[locationId];
      }
    });
    if (Object.keys(groupedLocations).length > 0) {
      return groupedLocations;
    }
  }
  return;
}

function getVariant(variantLocation, inventoryLocation, settings) {
  const variantDetails = window.inventoryInfo.product.variants.find(function(
    v
  ) {
    return v.id === variantLocation.variant;
  });
  return settings.hideSoldOut && inventoryLocation.quantity <= 0
    ? undefined
    : {
        id: variantLocation.variant,
        policy: variantLocation.policy,
        inventoryManagement: variantLocation.inventoryManagement,
        quantity: inventoryLocation.quantity,
        name: window.inventoryInfo.product.defaultVariantOnly
          ? ''
          : variantDetails.title,
        incoming: variantDetails.incoming,
        available: variantDetails.available
      };
}
