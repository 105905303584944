import { hasUntrackedOnlineLocation } from './createOnlineStoreLocation';

export function hideForSoldOut(currentVariant, settings, visibleLocationsOnly) {
  if (settings.showOnSoldOut) {
    return false;
  }
  if (
    hasUntrackedOnlineLocation(settings) &&
    !currentVariant.inventoryManagement
  ) {
    return false;
  }
  return visibleLocationsOnly
    ? isSoldOutAtVisibleLocations(currentVariant, settings.hiddenLocation)
    : isSoldOut(currentVariant);
}

export function isSoldOut(currentVariant) {
  const inStock = currentVariant.inventoryLocations.find(
    inventoryLocation => inventoryLocation.quantity > 0
  );

  return !inStock;
}
export function isSoldOutAtVisibleLocations(currentVariant, hiddenLocation) {
  const hideenLocations = hiddenLocation || {};
  const inStock = currentVariant.inventoryLocations.find(
    inventoryLocation =>
      inventoryLocation.quantity > 0 &&
      !hideenLocations[inventoryLocation.location.id]
  );

  return !inStock;
}
